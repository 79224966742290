/* eslint-disable react/no-danger */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */

import React, { useState, useRef } from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import { useDevice } from "~hooks";
import * as T from "~styles/Typography.jsx";
import Grid from "~styles/Grid.jsx";
import { Button, LineSwipe, WaveCanvas } from "~components";
import PageBuilder from "~sanity/PageBuilder.jsx";
import { MEDIA_QUERIES } from "~utils/css";

// import { ReactComponent as ArrowUp } from "~assets/svg/arrow-up.svg";

/** ============================================================================
 * @component
 * Tab selector that conditionally renders a collection of child sections
 */
const TabsSection = ({ data: { subheading, heading, body, tabs } }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { isDesktop } = useDevice();

  const topRef = useRef(null);

  const [activeTab, setActiveTab] = useState(tabs?.length > 0 ? tabs[0] : null);
  const [mobileListActive, setMobileListActive] = useState(false);

  // ---------------------------------------------------------------------------
  // methods

  const backToTop = () => {
    if (typeof window === `undefined`) {
      return;
    }

    topRef.current.scrollIntoView({ behavior: `smooth` });
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  // ---------------------------------------------------------------------------
  // variables

  return (
    <div css={[tw`relative pb-12 md:pb-0 bg-white`]} ref={topRef}>
      <div
        id={subheading?.replace(/\s+/g, `-`).toLowerCase()}
        css={[
          css`
            border-bottom: 1px solid black;
          `,
          tw`w-full relative pt-24 md:pt-32 flex flex-col items-center justify-center text-blue-deep`
        ]}
      >
        <WaveCanvas />

        <Grid _css={[tw`z-20`]}>
          <header css={[tw`col-span-12 relative flex items-center`]}>
            <T.Head font="4">{subheading}</T.Head>

            <div css={[tw`w-32 relative block ml-5`]}>
              <LineSwipe color="#84bbd9" delay={500} duration={2000} />
            </div>
          </header>

          <div
            css={[
              tw`col-span-12 md:col-span-4 relative mt-12 md:mt-20 md:mb-24`
            ]}
          >
            <T.Head _css={[tw`font-bold text-blue-med`]} font="2">
              {heading}
            </T.Head>
          </div>

          <div
            css={[
              tw`col-span-12 md:col-start-6 md:col-span-5 relative mt-12 md:mt-20 md:mb-24`
            ]}
          >
            <T.Body _css={[tw`text-black whitespace-pre-wrap`]} font="2">
              {body}
            </T.Body>
          </div>

          {(isDesktop() && (
            <div css={[tw`col-span-full relative flex items-stretch`]}>
              {tabs?.map((tab, i) => (
                <button
                  type="button"
                  onClick={() => setActiveTab(tab)}
                  css={[
                    css`
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                      background: white;
                      height: 5rem;
                    `,
                    tw`flex-1 p-3 pr-12 flex items-center text-left`,
                    i === 0 && tw`border-l`,
                    activeTab === tab && tw`bg-blue-med`
                  ]}
                >
                  <T.Body
                    _css={[
                      tw`font-bold text-black`,
                      activeTab === tab && tw`text-white`
                    ]}
                    font="2"
                  >
                    {tab.name}
                  </T.Body>
                </button>
              ))}
            </div>
          )) || (
            <div
              css={[
                css`
                  border: 1px solid black;
                `,
                tw`col-span-full relative block mt-20`
              ]}
            >
              <Button
                _css={[tw`w-full h-16`]}
                arrow="down"
                onClick={() => {
                  setMobileListActive(!mobileListActive);
                }}
                text="Choose service"
              />

              <div
                css={[
                  css`
                    transition: 0.5s ease max-height, 0.3s ease padding;

                    max-height: ${mobileListActive ? `1000px` : `0`};
                    padding: ${mobileListActive ? `1rem 0` : `0`};
                    border-top: 1px solid black;
                  `,
                  tw`overflow-hidden bg-white`
                ]}
              >
                {tabs?.map((tab, i) => (
                  <button
                    type="button"
                    onClick={() => {
                      setActiveTab(tab);
                      setMobileListActive(false);
                    }}
                    css={[tw`w-full relative block`]}
                  >
                    <div
                      css={[
                        css``,
                        tw`w-full relative flex flex-1 items-center py-3 px-6 text-left`
                      ]}
                    >
                      <T.Body _css={[tw`text-blue-turq`]} font="3">
                        {tab.name}
                      </T.Body>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          )}
        </Grid>
      </div>

      {activeTab?.pagebuilder?.sections?.[0] && (
        <PageBuilder pagebuilder={activeTab.pagebuilder} />
      )}

      {/* <div css={[tw`absolute block bottom-8 right-0 z-30`]}>
        <button
          type="button"
          css={[
            tw`w-28 h-16 z-30 bg-black`,
            css`
              svg {
                margin: auto;
                transition: 0.3s ease transform;
              }

              ${MEDIA_QUERIES.hoverable} {
                &:hover {
                  svg {
                    transform: translate3d(-1.5rem, 0, 0);
                  }
                }
              }
            `
          ]}
          onClick={backToTop}
          label="back-to-top"
        >
          <ArrowUp
            fill="white"
            css={[
              css`
                display: absolute;
                height: 50%;
              `
            ]}
          />
        </button>
      </div> */}
    </div>
  );
};

export default TabsSection;

/** ============================================================================
 * graphql
 * Issue: Cannot use ...PageBuilderFragment due to recursion with TabsSectionFragment
 *        Manually entered pagebuilder sections sans TabsSectionFragment, this is not ideal
 */
export const query = graphql`
  fragment TabsSectionFragment on SanityTabsSection {
    _type

    backgroundColor {
      hex
      title
    }
    fontColor {
      hex
      title
    }

    subheading
    heading
    body

    tabs {
      name

      pagebuilder {
        sections {
          ...ArticleCarouselSectionFragment
          ...ArticleCTASectionFragment
          ...ArticleGridSectionFragment
          ...ArticleMasonrySectionFragment
          ...BioGridSectionFragment
          ...Column3SectionFragment
          ...ColumnTextSectionFragment
          ...CoreServicesSectionFragment
          ...ExploreMoreSectionFragment
          ...FaqSectionFragment
          ...GetStartedSectionFragment
          ...ImageArticleSectionFragment
          ...ImageSectionFragment
          ...IntroductionSectionFragment
          ...LinkCarouselSectionFragment
          ...RelevantArticlesSectionFragment
          ...StepsSectionFragment
          ...TableTextSectionFragment
          ...TextCardSectionFragment
          ...TextGridButtonSectionFragment
          ...TextSectionFragment
          ...VideoSectionFragment
        }
      }
    }
  }
`;
